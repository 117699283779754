@import '~@stitch-fix/mode-style-system';

// hide Contentstack edit icons in live preview until we support inline edit
.cslp-tooltip {
  display: none;
}

// one trust cookie consent styles
// https://stitchfix.atlassian.net/wiki/spaces/FT/pages/3773202475/OneTrust+Integration
// stylelint-disable selector-max-specificity, selector-max-id
#onetrust-consent-sdk {
  font-family: $type-family, sans-serif;
}
//stylelint-enable selector-max-specificity, selector-max-id

/**
 * https://docs.percy.io/docs/percy-specific-css#percy-css-media-query
 */
@media only percy {
  /**
   * A link in Knit's footer works in conjunction with some unclear combination of
   * OneTrust, GTM, and cookielaw.org.
   *
   * It seems like some of our tests run in California and others do not, causing
   * flaky tests. Either that or the less interesting explanation that this is a race
   * condition. It remains obscured how exactly the script that toggles these links
   * is instantiated. Until that riddle gets solved, this funcationality is not
   * terribly important to our visual regression testings so this is the quickest path
   * to reduce flakiness.
   *
   * - [CccpaLinks Component](https://github.com/stitchfix/knit/blob/f36126a94c550ba1ecdc2e9f6aec8747def2a0b5/src/components/Footer/LegalLinks/CcpaLinks.tsx#L11)
   */
  .For-CA-Users {
    display: none;
  }
}
